body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #eeeeee;
}

/* Custom Table CSS */
:root {
  --table-border: 1px solid rgba(224, 224, 224, 1);
}

.custom-table {
  width: 100%;
  cursor: default;
  background-color: white;
  border-collapse: separate;
  border-spacing: 0;
}

.custom-table th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  padding-bottom: 4px;
  border-bottom: var(--table-border);
  border-right: var(--table-border);
}

.custom-table thead tr:first-child th {
  border-top: var(--table-border);
}

.custom-table td {
  padding-bottom: 2px;
  border-bottom: var(--table-border);
  border-right: var(--table-border);
}

.custom-table th:first-child,
.custom-table td:first-child {
  border-left: var(--table-border);
}

.custom-table,
.custom-table th,
.custom-table td {
  color: black;
  text-align: center;
}

.custom-table tr:hover td {
  background-color: #ffffcc;
}

.selected-row {
  background-color: #fdfda0;
}

/* if tbody-hover class is applied to <tbody> tag, 
then hover effect will be applied to the whole tag. */
.custom-table tbody.tbody-hover:hover {
  background-color: #ffffcc;
}

.borderless th {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
